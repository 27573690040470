<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-07-06 11:05:06
 * @Description: 数据统计
-->
<template>
  <div class="content">
    <div v-if="status === '1'" class="info">
      共有<span class="num">{{ enterpriseNum }}</span
      >企业的管理人员查看了此政策
    </div>
    <div v-if="status === '2'" class="info">
      共有<span class="num">{{ positionNum }}</span
      >人查看了此政策
    </div>
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab="企业统计">
        <a-table
          :rowKey="(record, index) => index"
          :columns="table.enterpriseColumns"
          :data-source="table.enterpriseTableData"
          :pagination="table.pagination.total ? table.pagination : false"
          @change="pageChange"
        >
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="职位统计">
        <a-table
          :rowKey="(record, index) => index"
          :columns="table.positionColumns"
          :data-source="table.positionTableData"
          :pagination="table.pagination.total ? table.pagination : false"
          @change="pageChange"
        >
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { policyReportDataStatistic } from "@/api/entrepreneurClubManagement/policyManagement";
export default {
  name: "dataStatistics",
  data() {
    return {
      enterpriseNum: 0,
      positionNum: 0,
      status: "1",
      table: {
        enterpriseTableData: [],
        positionTableData: [],
        enterpriseColumns: [
          {
            title: "企业名称",
            dataIndex: "enterpriseName",
            key: "enterpriseName",
          },
          {
            title: "查看次数",
            dataIndex: "viewCount",
            key: "viewCount",
            width: 220,
          },
        ],
        positionColumns: [
          {
            title: "职位名称",
            dataIndex: "positionName",
            key: "positionName",
          },
          {
            title: "查看次数",
            dataIndex: "viewCount",
            key: "viewCount",
            width: 220,
          },
        ],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: false,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return ``;
          },
        },
      },
    };
  },
  props: {
    policyId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    tabChange(val) {
      this.status = val;
      this.getList();
    },
    // 列表数据
    async getList() {
      const data = {
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize,
        viewType: this.status === "1" ? "enterprise" : "position",
        policyId: this.policyId,
      };

      try {
        const res = await policyReportDataStatistic(data);
        if (res.code === "200") {
          if (this.status === "1") {
            this.table.enterpriseTableData = res.data.enterpriseData.list;
            this.enterpriseNum = res.data.enterpriseData.enterpriseTotal;
            this.table.pagination.total = res.data.enterpriseData.total;
          } else {
            this.table.positionTableData = res.data.positionData.list;
            this.positionNum = res.data.positionData.personTotal;
            this.table.pagination.total = res.data.positionData.total;
          }
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
  },
};
</script>

<style lang='scss' scoped>
.num {
  color: #1890ff;
}
.info {
  margin-left: 40%;
  width: 300px;
}
.ant-tabs {
  margin-top: -35px;
}
.content{
  min-height: 55vh;
}
</style>